.root {
  padding: 20px;

  .cicd {
    position: relative;

    textarea {
      width: calc(100% - 200px);
      height: 100px;
    }

    button {
      position: absolute;
      right: 0;
      width: 180px;
      height: 30px;
    }
  }

  .json-editor {
    position: relative;

    button {
      position: absolute;
      right: 0;
      width: 180px;
      bottom: 0;
      height: 30px;
      z-index: 100;
    }
  }
}
